//@ts-check
import { get, post } from './apiClient';

export function getUser() {
  return get('user');
}

export function loginWithIdToken(idToken) {
  return post('auth', { idToken: idToken });
}

export function getCalls() {
  return get('call');
}

export function callHandle(callId) {
  return get('call-handle', { callId });
}

export function callHandled(callId) {
  return get('call-handled', { callId });
}

export function callPrioHigher(callId) {
  return get('call-prio-higher', { callId });
}

export function callPrioLower(callId) {
  return get('call-prio-lower', { callId });
}

export function getIssues() {
  return get('issue');
}
