import React, { useState, useEffect, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getUser } from '../helpers/api';

export const ApiContext = createContext();

export function ApiContextProvider({ children }) {
  const [apiError, setApiError] = useState('');
  const [user, setUser] = useState('');
  const [msalLoginRequired, setMsalLoginRequired] = useState('');

  ApiContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  useMemo(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response && error.response.status === 401) {
          setUser('');
          setMsalLoginRequired('login');
        } else if (error.response && error.response.data.error) {
          setApiError(error.response.data.error);
        } else {
          setApiError(error.message);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (apiError) {
      setTimeout(() => {
        setApiError('');
      }, 3000);
    }
  }, [apiError]);

  useEffect(() => {
    if (!user) {
      if (!msalLoginRequired) {
        getUser().then((resp) => {
          setUser(resp);
        });
      }
    } else if (msalLoginRequired) {
      // succesfully logged in, reset msalState
      setMsalLoginRequired('');
    }
  }, [user, msalLoginRequired]);

  const contextValue = useMemo(() => {
    return { apiError, user, setUser };
  }, [apiError, user]);

  if (!user && !msalLoginRequired) {
    return 'Retrieving user details...';
  }

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>;
}
