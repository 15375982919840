import React from 'react';

function Loading() {
  return (
    <div className="d-flex justify-content-center fade-spinner">
      <div className="spinner-grow" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
