import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import { AppContext } from '../contexts/AppContext';
import { getCalls, getIssues } from '../helpers/api';

function DataLoader({ children }) {
  const appContext = useContext(AppContext);
  const [shouldUpdateCalls, setShouldUpdateCalls] = useState();

  const loadIssues = useCallback(async () => {
    const response = await getIssues();
    appContext.setIssues(response);
    appContext.setIssuesOutdated(false);
  }, [appContext]);

  const loadCalls = useCallback(async () => {
    const response = await getCalls();
    appContext.setCalls(response);
    appContext.setCallsOutdated(false);
  }, [appContext]);

  const onVisibilityChange = useCallback(async () => {
    const state = document.visibilityState;
    if (state === 'hidden') {
      appContext.setTimeHidden(new Date().getTime());
    } else if (state === 'visible' && appContext.timeHidden) {
      let lastSec = parseInt(appContext.timeHidden / 1000);
      let nowSec = parseInt(new Date().getTime() / 1000);
      if (nowSec - lastSec > 10) {
        appContext.setIssuesOutdated(true);
        appContext.setCallsOutdated(true);
        appContext.setTimeHidden(null);
        loadIssues();
        loadCalls();
      }
    }
  }, [appContext, loadIssues, loadCalls]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onVisibilityChange]);

  useEffect(() => {
    if (appContext.issues === null && appContext.calls === null) {
      loadIssues();
      loadCalls();
    }
  }, [appContext, loadIssues, loadCalls]);

  useEffect(() => {
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
    if (!isMobileDevice) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: process.env.REACT_APP_API_URL + '/pusher-auth',
        },
      });
      Pusher.Runtime.createXHR = function () {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        return xhr;
      };
      const channel = pusher.subscribe('private-calls');
      channel.bind('update', function (data) {
        if (process.env.NODE_ENV === 'development') {
          console.log(data);
        }
        setShouldUpdateCalls(true);
      });

      return () => {
        channel.unbind_all();
        console.log('unsubscribe');
        pusher.unsubscribe('private-calls');
      };
    }
  }, []);

  useEffect(() => {
    if (shouldUpdateCalls) {
      setShouldUpdateCalls(false);
      loadCalls();
    }
  }, [loadCalls, shouldUpdateCalls]);

  return <>{children}</>;
}

DataLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataLoader;
