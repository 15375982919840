import React from 'react';

function Outdated() {
  return (
    <div className="alert alert-warning fixed-bottom text-center mb-0" role="alert">
      Outdated
    </div>
  );
}

export default Outdated;
