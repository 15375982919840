import React, { useContext, useState } from 'react';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { Button } from 'react-bootstrap';
import { ApiContext } from '../contexts/ApiContext';

function Settings() {
  const [beamsStatus, setBeamsStatus] = useState('');
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  const { user } = useContext(ApiContext);

  function changePersonalNotifications(enable) {
    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: process.env.REACT_APP_API_URL + '/pusher-beams',
      credentials: 'include',
    });
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: process.env.REACT_APP_PUSHER_INSTANCE_ID,
    });

    if (enable) {
      beamsClient
        .start()
        .then(() => beamsClient.setUserId(user.initials, beamsTokenProvider))
        .then(() => setBeamsStatus('Successfully registered to personal notifications'))
        .catch(console.error);
    } else {
      beamsClient
        .stop()
        .then(() => setBeamsStatus('Unregistered from personal notifications'))
        .catch(console.error);
    }
  }

  function getPersonalRegistrationStatus() {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: process.env.REACT_APP_PUSHER_INSTANCE_ID,
    });

    beamsClient
      .getUserId()
      .then((userId) => {
        if (userId) {
          setBeamsStatus('Receiving notifications for: ' + userId);
        } else {
          setBeamsStatus('Not registered to personal notifications');
        }
      })
      .catch(console.error);
  }

  return (
    <>
      <h3>Settings</h3>
      <div>Status: {beamsStatus}</div>
      <h5>Push notifications: personal</h5>
      <div className="mb-3">
        <Button onClick={() => changePersonalNotifications(true)} className="me-2">
          Enable
        </Button>
        <Button onClick={() => changePersonalNotifications(false)} className="me-2">
          Disable
        </Button>
        <Button onClick={() => getPersonalRegistrationStatus(false)}>Status</Button>
      </div>
      <div className="text-muted">Device type: {isMobileDevice ? 'mobile' : 'browser'}</div>
    </>
  );
}

export default Settings;
