//@ts-check
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

/**
 * Executes GET request on API url and extracts data from response
 * @param {string} url
 * @param {any} [params=null] Params to send
 * @returns {Promise<any>} Data object from API response
 */
export async function get(url, params = null) {
  try {
    if (params) {
      // remove empty params
      Object.keys(params).forEach((key) => {
        if (params[key] === '') {
          delete params[key];
        }
      });
    }
    const response = await apiRequest('GET', url, null, params);
    return response.data;
  } catch (error) {
    return null;
  }
}

/**
 * Executes POST request on API url and extracts data from response
 * @param {string} url
 * @param {any} data
 * @returns {Promise<any>} Data object from API response
 */
export async function post(url, data) {
  try {
    const response = await apiRequest('POST', url, data);
    return response.data;
  } catch (error) {
    return null;
  }
}

/**
 * Executes request on API url with specified method/data
 * @param {*} method GET/POST/PUT/PATCH/DELETE
 * @param {string} url The url of the API endpoint
 * @param {array} [data=null] Data to send
 * @param {array} [params=null] Params to send
 * @returns {Promise<{data:any}>} Promise with the response
 */
async function apiRequest(method, url, data = null, params = null) {
  try {
    const response = await axios({
      url,
      method: method,
      data: data,
      params: params,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}
