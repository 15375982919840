import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';
import Outdated from '../components/Outdated';
import { AppContext } from '../contexts/AppContext';

function ServiceIssues({ service }) {
  let border = '';
  if (service.class == 'warning') {
    border = 'warning';
  } else if (service.class == 'critical') {
    border = 'danger';
  }

  return (
    <>
      <Card border={border} className="ps-1 pe-2">
        <Card.Title className="fs-6">{service.service.toLowerCase()}</Card.Title>
        <Card.Subtitle className="mb-0 text-muted">{service.duration}</Card.Subtitle>
      </Card>
    </>
  );
}

ServiceIssues.propTypes = {
  service: PropTypes.object.isRequired,
};

function HostIssues({ host }) {
  return (
    <>
      <div className="d-inline-flex p-1 me-2">
        <Card className="me-2">
          <Card.Body className="p-1 pt-0">
            <Card.Title className="mb-1">{host.host}</Card.Title>
          </Card.Body>
        </Card>
        {host.services.map((service) => (
          <ServiceIssues service={service} key={service.service} />
        ))}
      </div>
    </>
  );
}

HostIssues.propTypes = {
  host: PropTypes.object.isRequired,
};

function ClientIssues({ client }) {
  return (
    <>
      <Card className="mb-2">
        <Card.Header as="h5" className="pt-0 pb-1">
          {client.client}
        </Card.Header>
        <Card.Body className="p-0">
          {client.hosts.map((host) => (
            <HostIssues host={host} key={host.host} />
          ))}
        </Card.Body>
      </Card>
    </>
  );
}

ClientIssues.propTypes = {
  client: PropTypes.object.isRequired,
};

function CheckMK() {
  const { issues, issuesOutdated, swiped } = useContext(AppContext);

  if (swiped === 'right/checkmk') {
    return <Navigate to="/" />;
  }
  if (issues === null) {
    return <Loading />;
  }
  if (issues.length === 0) {
    return (
      <div className="alert alert-success" role="alert">
        No issues
      </div>
    );
  }

  return (
    <>
      {issuesOutdated ? <Outdated /> : ''}
      {issues.map((client) => (
        <ClientIssues client={client} key={client.client} />
      ))}
    </>
  );
}

export default CheckMK;
