import React, { useContext, useState, useEffect } from 'react';
import { ExclamationTriangle, Gear, Telephone } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppContext } from '../contexts/AppContext';

function MenuLink({ to, title, icon }) {
  return (
    <li className="nav-item">
      <NavLink to={to} className="nav-link">
        <span className="me-sm-2">{icon}</span>
        <span className="d-none d-sm-inline">{title}</span>
      </NavLink>
    </li>
  );
}

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

function Menu() {
  const { calls } = useContext(AppContext);
  const [nrCalls, setNrCalls] = useState();

  useEffect(() => {
    if (calls) {
      // count nr of unhandled calls to show in menu link and document title
      let nr = '';
      let emergency = false;
      let count = 0;
      calls.forEach((call) => {
        if (call.actionRequired && !call.handledBy) {
          count++;
          if (call.emergency) {
            emergency = true;
          }
        }
      });
      if (count) {
        nr = count;
      }
      if (emergency) {
        nr += '!';
      }
      // update document title
      const baseTitle = 'CNS Web app';
      if (nr) {
        document.title = `[${nr}] - ${baseTitle}`;
      } else {
        document.title = baseTitle;
      }
      navigator.setAppBadge(count);
      setNrCalls(nr);
    }
  }, [calls]);

  return (
    <>
      <span className="fs-5 mt-3">
        <span className="d-none d-sm-inline p-4">CNS - CW</span>
        <hr />
      </span>
      <ul className="nav nav-pills mb-auto nav-flush flex-sm-column flex-row flex-nowrap mx-auto">
        <MenuLink
          to="/"
          title="Calls"
          icon={
            <>
              <Telephone />
              {nrCalls}
            </>
          }
        />
        <MenuLink to="/checkmk" title="CheckMK" icon={<ExclamationTriangle />} />
      </ul>
      <ul className="nav nav-pills py-sm-4 mt-sm-auto ms-0 ms-sm-0 flex-shrink-1 mb-auto mb-sm-0">
        <li className="nav-item">
          <NavLink to="/settings" className="nav-link link-dark">
            <span className="me-sm-2">
              <Gear />
            </span>
            <span className="d-none d-sm-inline">Settings</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}

export default Menu;
