import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [timeHidden, setTimeHidden] = useState(null);
  const [swiped, setSwiped] = useState(null);
  const [issues, setIssues] = useState(null);
  const [calls, setCalls] = useState(null);
  const [issuesOutdated, setIssuesOutdated] = useState(null);
  const [callsOutdated, setCallsOutdated] = useState(null);

  AppContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const contextValue = useMemo(() => {
    function updateCall(changedCall) {
      const newCalls = calls.map((call) => (call.callId === changedCall.callId ? changedCall : call));
      setCalls(newCalls);
    }

    return {
      timeHidden,
      setTimeHidden,
      swiped,
      setSwiped,
      issues,
      setIssues,
      calls,
      setCalls,
      updateCall,
      issuesOutdated,
      setIssuesOutdated,
      callsOutdated,
      setCallsOutdated,
    };
  }, [timeHidden, swiped, issues, calls, issuesOutdated, callsOutdated]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}
