import React, { useContext } from 'react';
import { Button, Card } from 'react-bootstrap';
import { ExclamationTriangle, SortDown, Telephone } from 'react-bootstrap-icons';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';
import Outdated from '../components/Outdated';
import { AppContext } from '../contexts/AppContext';
import { callHandle, callHandled, callPrioHigher, callPrioLower } from '../helpers/api';

function Call({ call }) {
  const { updateCall } = useContext(AppContext);

  async function onHandle() {
    const newCall = await callHandle(call.callId);
    if (newCall) {
      updateCall(newCall);
    }
  }

  async function onHandled() {
    const newCall = await callHandled(call.callId);
    if (newCall) {
      updateCall(newCall);
    }
  }

  async function onPrioLower() {
    const newCall = await callPrioLower(call.callId);
    if (newCall) {
      updateCall(newCall);
    }
  }

  async function onPrioHigher() {
    const newCall = await callPrioHigher(call.callId);
    if (newCall) {
      updateCall(newCall);
    }
  }

  const company = call.company ? ` (${call.company})` : '';
  let border = '';
  if (call.emergency) {
    border = 'danger';
  } else if (call.actionRequired) {
    border = 'warning';
  }
  let bg = '';
  if (call.actionRequired && !call.handledAt && !call.beingHandledAt) {
    if (call.emergency) {
      bg = 'danger';
    } else if (call.actionRequired) {
      bg = 'warning';
    }
  }
  let footer = '';
  if (call.handledBy) {
    if (call.handledAt) {
      footer = `Handled by: ${call.handledBy} @ ${call.beingHandledAt} <-> ${call.handledAt}`;
    } else {
      footer = `Being handled by: ${call.handledBy} @ ${call.beingHandledAt}`;
    }
  }
  if (footer) {
    footer = <div className="text-muted">{footer}</div>;
  }

  return (
    <>
      <Card className="mb-2" border={border} bg={bg} text={bg == 'danger' ? 'white' : ''}>
        <Card.Body>
          <Card.Title>
            {call.name}
            {company}
          </Card.Title>
          <Card.Subtitle className={bg == 'danger' ? 'fst-italic' : 'text-muted'}>
            {call.time}
            {call.destinationEmployee ? ` [${call.destinationEmployee}]` : ''}
          </Card.Subtitle>
          <Card.Text>
            {call.description && (
              <>
                {call.description}
                <br />
              </>
            )}
            {call.phonenr && (
              <>
                {call.phonenr}{' '}
                <a href={'tel:' + call.phonenr} className="text-black">
                  <Telephone />
                </a>
                {call.cnsContact && <> [{call.cnsContact}]</>}
                <br />
              </>
            )}
            {call.actionRequired && !call.handledBy && (
              <Button onClick={onHandle} className="me-2">
                Handle
              </Button>
            )}
            {call.actionRequired && !call.handledAt && (
              <Button onClick={onHandled} className="me-2">
                Handled
              </Button>
            )}
            {call.actionRequired && !call.handledAt && call.emergency && (
              <Button onClick={onPrioLower}>
                <SortDown />
              </Button>
            )}
            {call.actionRequired && !call.handledAt && !call.emergency && (
              <Button onClick={onPrioHigher}>
                <ExclamationTriangle />
              </Button>
            )}
          </Card.Text>
          {footer}
        </Card.Body>
      </Card>
    </>
  );
}

Call.propTypes = {
  call: PropTypes.object.isRequired,
};

function Calls() {
  const { calls, callsOutdated, swiped } = useContext(AppContext);

  if (swiped === 'left/') {
    return <Navigate to="/checkmk" />;
  }

  if (calls === null) {
    return <Loading />;
  }

  let open = [];
  let handled = [];
  calls.forEach((call) => {
    if (call.actionRequired && !call.handledAt) {
      open.push(call);
    } else {
      handled.push(call);
    }
  });

  return (
    <>
      {callsOutdated ? <Outdated /> : ''}
      {open.length === 0 && (
        <div className="alert alert-success" role="alert">
          No unhandled calls
        </div>
      )}
      {open.map((call) => (
        <Call call={call} key={call.callId} />
      ))}
      <h3>Handled</h3>
      {handled.map((call) => (
        <Call call={call} key={call.callId} />
      ))}
    </>
  );
}

export default Calls;
