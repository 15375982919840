import React, { useContext, useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  MsalProvider,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import PropTypes from 'prop-types';
import { PublicClientApplication } from '@azure/msal-browser';
import config from '../config/config';
import { ApiContext } from '../contexts/ApiContext';
import { loginWithIdToken } from '../helpers/api';

const pca = new PublicClientApplication(config.OAuth);

function MsalLoginComplete() {
  const { user, setUser } = useContext(ApiContext);
  const { instance, accounts } = useMsal();

  const login = useCallback(async () => {
    async function getMsalIdToken() {
      const account = accounts[0];
      const response = await instance
        .acquireTokenSilent({
          account,
        })
        .catch((err) => {
          if (err.name === 'InteractionRequiredAuthError') instance.logout();
        });

      if (!response) window.location.reload(); // If the refresh token exprired, reload the page to force re-auth

      return response.idToken;
    }

    const idToken = await getMsalIdToken();
    loginWithIdToken(idToken).then((resp) => {
      setUser(resp);
    });
  }, [setUser, accounts, instance]);

  useEffect(() => {
    if (!user) {
      login();
    }
  }, [user, login]);

  if (user) {
    return <Navigate to="/" />;
  }

  return <>Logging in application...</>;
}

function MsalLogin() {
  useMsalAuthentication('redirect');

  return (
    <>
      <AuthenticatedTemplate>
        <MsalLoginComplete />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>Logging in Azure AD...</UnauthenticatedTemplate>
    </>
  );
}

function Login() {
  return (
    <MsalProvider instance={pca}>
      <MsalLogin />
    </MsalProvider>
  );
}

function LoginWrapper({ children }) {
  const { apiError, user } = useContext(ApiContext);

  if (!user) {
    return (
      <>
        <Login />
        {apiError && <div className="alert alert-danger mt-2">{apiError}</div>}
      </>
    );
  }

  return <>{children}</>;
}

LoginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginWrapper;
