const config = {
  OAuth: {
    auth: {
      clientId: 'aeae89d0-2d95-40bc-9427-10ea3b49a7d4',
      authority: 'https://login.microsoftonline.com/0ebc4dba-f1ab-4cac-8b0d-c4ecb95c0738',
      redirectUri: location.protocol.concat('//').concat(window.location.host) + '/',
    },
  },
};

export default config;
