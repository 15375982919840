import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import DataLoader from './components/DataLoader';
import ErrorFallback from './components/ErrorFallback';
import { ApiContextProvider } from './contexts/ApiContext';
import { AppContextProvider } from './contexts/AppContext';
import LoginWrapper from './components/LoginWrapper';
import Layout from './layout/Layout';
import Calls from './pages/Calls';
import CheckMK from './pages/CheckMK';
import Settings from './pages/Settings';

function NotFound() {
  return <div>Page not found</div>;
}

function App() {
  return (
    <>
      <BrowserRouter basename={process.env.MIX_APP_BASEDIR}>
        <ApiContextProvider>
          <LoginWrapper>
            <AppContextProvider>
              <DataLoader>
                <Layout>
                  <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
                    <Routes>
                      <Route path="/" element={<Calls />} />
                      <Route path="checkmk" element={<CheckMK />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </ErrorBoundary>
                </Layout>
              </DataLoader>
            </AppContextProvider>
          </LoginWrapper>
        </ApiContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
