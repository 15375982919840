import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import PropTypes from 'prop-types';
import { ApiContext } from '../contexts/ApiContext';
import { AppContext } from '../contexts/AppContext';
import Menu from './Menu';

function Layout({ children }) {
  const { apiError } = useContext(ApiContext);
  const { setSwiped } = useContext(AppContext);
  const { pathname } = useLocation();
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left' || eventData.dir === 'Right') {
        setSwiped(eventData.dir.toLowerCase() + pathname);
        setTimeout(() => {
          setSwiped(null);
        }, 100);
      }
    },
  });

  return (
    <>
      <div className="container-fluid overflow-hidden">
        <div className="row vh-100 overflow-auto">
          <div className="col-sm-auto px-sm-1 px-0 d-flex sticky-top bg-light">
            <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-0 pt-2 bg-light">
              <Menu />
            </div>
          </div>
          <div className="vertical-divider d-none d-sm-inline sticky-top"></div>
          <div className="col d-flex flex-column h-100" {...handlers}>
            <main className="row p-2">
              {apiError && (
                <div className="alert alert-danger" role="alert">
                  {apiError}
                </div>
              )}
              {children}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
